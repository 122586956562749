import data from '../../assets/Gallery';
import VisualizeTile from './VisualizeTile';

const VisualizeGalery = () => {
	return (
		<section id='gallery'>
			<div className='section__text'>
				<h2>Galeria</h2>
				<p>*Naciśnij na zdjęcie, aby przybliżyć</p>
			</div>
			<div className='visualize__gallery'>
				{data.map((item) => (
					<VisualizeTile key={item.title} data={item} />
				))}
			</div>
		</section>
	);
};

export default VisualizeGalery;
