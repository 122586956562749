import VisualizeGalery from '../components/Visualize/VisualizeGalery';
import '../components/Visualize/Visualize.scss';

const VisualizePage = () => {
	return (
		<>
			<VisualizeGalery />
		</>
	);
};

export default VisualizePage;
