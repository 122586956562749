import { list } from '../../assets/MainList';

const MainList = () => {
	return (
		<section id='list'>
			<div className='section__text'>
				<h2>Nasza Oferta</h2>
			</div>
			<div className='main__list'>
				{list.map((item) => (
					<ul key={item.header}>
						<h3>{item.header}</h3>
						{item.content.map((text) => (
							<li key={text}>
								<span>{text}</span>
								<div className='prefix'>
									<span>{item.prefix}</span>
								</div>
							</li>
						))}
						{item.additional.map((text) => (
							<p key={text}>{text}</p>
						))}
					</ul>
				))}
			</div>
		</section>
	);
};

export default MainList;
