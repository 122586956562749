import voucher from '../../assets/images/voucher__main.jpeg';
import flyer from '../../assets/images/flyer__main.jpeg';
// import LazyImage from '../UI/LazyImage';

const MainPhotos = () => {
	return (
		<section className='main__photos'>
			{/* <LazyImage src={voucher} alt='Voucher strony głównej' />
			<LazyImage src={flyer} alt='Ulotka strony głównej' /> */}
			<img src={voucher} alt='Voucher strony głównej' />
			<img src={flyer} alt='Ulotka strony głównej' />
		</section>
	);
};

export default MainPhotos;
