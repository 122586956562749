import './App.scss';
import {
	createBrowserRouter,
	Navigate,
	Outlet,
	RouterProvider,
} from 'react-router-dom';
import MainPage from './pages/Main';
import Footer from './components/Footer/Footer';
import Navigation from './components/Navigation/Navigation';
import VisualizePage from './pages/Visualize';
import ContactPage from './pages/Contact';
import { headerData } from './assets/HeaderData';
import Header from './components/UI/Header';

export const DefaultPage = () => {
	return (
		<>
			<Navigate to='/main' />
			<Navigation />
			<Header data={headerData} />
			<main className='container'>
				<Outlet />
			</main>
			<Footer />
		</>
	);
};

const router = createBrowserRouter([
	{
		path: '/',
		element: <DefaultPage />,
		children: [
			{ path: 'main', element: <MainPage /> },
			{ path: 'visualize', element: <VisualizePage /> },
			{ path: 'contact', element: <ContactPage /> },
		],
	},
]);

const App = () => {
	return <RouterProvider router={router} />;
};

export default App;
