import './Footer.scss';
import FooterNavigation from './FooterNavigation';
import logo from '../../assets/images/logo.png';
import Container from '../UI/Container';

const data = [
	'PRZEDSIĘBIORSTWO HANDLOWO USŁUGOWE MICHAŁ ŚLUSARCZYK',
	'26-067 Chełmce, ul. Dębowa 11',
	'NIP: PL 9591409210',
	'Adres e-mail: michal.slusarczyk123@gmail.com',
	'tel. 505947215',
];

const Footer = () => {
	return (
		<footer className='footer'>
			<Container className='footer__content'>
				<img src={logo} alt='Logo MSDRUK' />
				<article className='footer__data'>
					{data.map((text) => (
						<p key={text}>{text}</p>
					))}
				</article>
				<FooterNavigation />
			</Container>
			<Container className='footer__content'>
				<p>copyright &copy; MSDRUK</p>
				<p>{new Date().getFullYear()}</p>
				<div>
					<span>Do góry</span>
				</div>
			</Container>
		</footer>
	);
};

export default Footer;
