export const list = [
	{
		header: '↓ Wydruki w jakości HD ↓',
		prefix: 'HD',
		additional: [
			'*Wydruk książek i okładek na książki w pojedynczych oraz niskonakładowych egzemplarzach.',
			'*Druk na foliach FOLEX CLP – np. paneli dla branż AGD i producentów samochodowych.',
		],
		content: [
			'Zaproszenia',
			'Wizytówki',
			'Materiały informacyjne i szkoleniowe',
			'Papier firmowy',
			'Plakaty, ulotki',
			'Teczki',
			'Naklejki',
			'Bilety',
			'Kolorowanki, kolorowe gazetki',
			'Niskonakładowe foldery',
			'Kalendarze, kalendaria, główki do kalendarzy',
			'Banery formatu 330x660 mm',
		],
	},
	{
		header: '↓ Możliwość oprawy w linii ↓',
		prefix: 'AD',
		additional: ['*AD -> Skrót od dodatku'],
		content: [
			'Zszywanie',
			'Dziurkowanie 2 lub 4 otwory',
			'Szycie zeszytowe z przycięciem do formatu',
			'Bigowanie do 6 równoległych big na arkuszu',
		],
	},
];
