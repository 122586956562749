const VisualizeTile = ({ data }) => {
	const { title, images } = data;

	return (
		<div className='visualize__tile'>
			<h3>{title}</h3>
			<div className='images__container'>
				{images.map((image) => (
					<div key={image.alt} className='visualize__tile--image'>
						<img src={image.src} alt={image.alt} />
					</div>
				))}
			</div>
		</div>
	);
};

export default VisualizeTile;
