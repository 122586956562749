import { useLocation } from 'react-router-dom';
import Container from './Container';
import './Header.scss';
import LazyImage from './LazyImage';

const Header = (props) => {
	const location = useLocation();
	const locName = location.pathname.replace(/^\//, '');

	let content = <p>Data error</p>;

	props.data.forEach((data) => {
		if (data.name === locName) {
			const { heading, text, button, imageData } = data;

			content = (
				<header className='header'>
					<Container className='header__container'>
						<div className='header__content'>
							<div className='header__content-text'>
								{heading}
								{text}
							</div>
							<a className='header__content-button' href={button.href}>
								<div className='icon'>
									<i className='fa-solid fa-angle-right'></i>
								</div>
								<span>{button.text}</span>
							</a>
						</div>
						<LazyImage src={imageData.src} alt={imageData.alt} />
					</Container>
				</header>
			);
		}
	});

	return <header>{content}</header>;
};

export default Header;
