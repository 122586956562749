const navLinks = [
	{ title: 'Główna', children: ['Start', 'Pokaz', 'Nasza Oferta'] },
	{ title: 'Wizualizacja', children: ['Start', 'Galeria'] },
	{ title: 'Kontakt', children: ['Start', 'Napisz do nas', 'Poczta'] },
];

const FooterNavigation = () => {
	return (
		<div className='footer__links'>
			{navLinks.map((item) => (
				<article key={item.title}>
					<h3>{item.title}</h3>
					<ul>
						{item.children.map((link) => (
							<li key={`${item.title}-${link}`}>
								<span className='footer__link'>{link}</span>
							</li>
						))}
					</ul>
				</article>
			))}
		</div>
	);
};

export default FooterNavigation;
