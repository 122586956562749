import { NavLink } from 'react-router-dom';
import './Navigation.scss';
import { FaBars, FaTimes } from 'react-icons/fa';
import Container from '../UI/Container';
import logo from '../../assets/images/logo.png';
import { useRef } from 'react';

const Navigation = () => {
	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle('responsive_nav');
	};

	return (
		<nav className='nav'>
			<Container className='nav__container'>
				<div className='nav__content'>
					<img src={logo} alt='MSDRUK logo' />
					<div className='nav__links' ref={navRef}>
						<NavLink
							to='/main'
							className={({ isActive }) => (isActive ? 'active-link' : '')}
							onClick={showNavbar}>
							Główna
						</NavLink>
						<NavLink
							to='/visualize'
							className={({ isActive }) => (isActive ? 'active-link' : '')}
							onClick={showNavbar}>
							Wizualizacja
						</NavLink>
						<NavLink
							to='/contact'
							className={({ isActive }) => (isActive ? 'active-link' : '')}
							onClick={showNavbar}>
							Kontakt
						</NavLink>
						<button className='nav-btn nav-close-btn' onClick={showNavbar}>
							<FaTimes />
						</button>
					</div>
				</div>
				<button className='nav-btn' onClick={showNavbar}>
					<FaBars />
				</button>
			</Container>
		</nav>
	);
};

export default Navigation;
