import main from '../assets/images/paper_plane.png';
import visualize from '../assets/images/paper_planes.png';
import contact from '../assets/images/contact_mail.png';

export const headerData = [
	{
		name: 'main',
		heading: (
			<h1>
				Więcej Niż Druk, <br /> Jakość To Nasze Motto
			</h1>
		),
		text: (
			<p>
				Ogromny wachlarz wyboru dla Twojej firmy!
				<br />
				Twój pomysł, Nasze wykonanie.
			</p>
		),
		button: {
			href: '#list',
			text: 'Sprawdź Sam!',
		},
		imageData: {
			src: main,
			alt: 'Na razie nic',
		},
	},
	{
		name: 'visualize',
		heading: (
			<h1>
				Nasz Druk, <br /> Twoja Przewaga Rynku
			</h1>
		),
		text: (
			<p>
				Dokładność oraz jakość to nasz priorytet!
				<br />
				Zaufaj nam, a nie zawiedziesz się.
			</p>
		),
		button: {
			href: '#gallery',
			text: 'Przekonaj się sam!',
		},
		imageData: {
			src: visualize,
			alt: 'Na razie nic',
		},
	},
	{
		name: 'contact',
		heading: (
			<h1>
				Masz pytania? <br /> Napisz Do Nas Teraz!
			</h1>
		),
		text: (
			<p>
				Zawsze jesteśmy gotowi, by odpowiedzieć na Twoje pytania.
				<br />
				Komunikacja to podstawa!
			</p>
		),
		button: {
			href: '#post',
			text: 'Nasza Poczta',
		},
		imageData: {
			src: contact,
			alt: 'Na razie nic',
		},
	},
];
