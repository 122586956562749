import menu1 from '../assets/images/gallery/menu1.jpeg';
import menu2 from '../assets/images/gallery/menu2.jpg';
import menu3 from '../assets/images/gallery/menu3.jpeg';
import menu4 from '../assets/images/gallery/menu4.jpg';

import wizyt1 from '../assets/images/gallery/wizyt1.jpg';
import wizyt2 from '../assets/images/gallery/wizyt2.jpg';
import wizyt3 from '../assets/images/gallery/wizyt3.jpeg';
import wizyt4 from '../assets/images/gallery/wizyt4.jpg';

import ulotka1 from '../assets/images/gallery/ulotka1.jpeg';
import ulotka2 from '../assets/images/gallery/ulotka2.jpg';
import ulotka3 from '../assets/images/gallery/ulotka3.jpg';
import ulotka4 from '../assets/images/gallery/uloltka4.jpg';

import oferta1 from '../assets/images/gallery/oferta1.jpg';
import oferta2 from '../assets/images/gallery/oferta2.jpg';
import oferta3 from '../assets/images/gallery/oferta3.jpg';

import voucher1 from '../assets/images/gallery/voucher1.jpg';
import voucher2 from '../assets/images/gallery/voucher2.jpg';
import voucher3 from '../assets/images/gallery/voucher3.jpg';

import inne1 from '../assets/images/gallery/inne1.jpg';
import inne2 from '../assets/images/gallery/inne2.jpg';

const data = [
	{
		title: 'Menu',
		images: [
			{
				src: menu1,
				alt: 'Pierwsze Zdjęcie przedstawiające Menu',
			},
			{
				src: menu2,
				alt: 'Drugie Zdjęcie przedstawiające Menu',
			},
			{
				src: menu3,
				alt: 'Trzecie Zdjęcie przedstawiające Menu',
			},
			{
				src: menu4,
				alt: 'Czwarte Zdjęcie przedstawiające Menu',
			},
		],
	},
	{
		title: 'Wizytówki',
		images: [
			{
				src: wizyt1,
				alt: 'Pierwsze Zdjęcie przedstawiające Wizytówkę',
			},
			{
				src: wizyt2,
				alt: 'Drugie Zdjęcie przedstawiające Wizytówkę',
			},
			{
				src: wizyt3,
				alt: 'Trzecie Zdjęcie przedstawiające Wizytówkę',
			},
			{
				src: wizyt4,
				alt: 'Czwarte Zdjęcie przedstawiające Wizytówkę',
			},
		],
	},
	{
		title: 'Ulotki',
		images: [
			{
				src: ulotka1,
				alt: 'Pierwsze Zdjęcie przedstawiające Ulotkę',
			},
			{
				src: ulotka2,
				alt: 'Drugie Zdjęcie przedstawiające Ulotkę',
			},
			{
				src: ulotka3,
				alt: 'Trzecie Zdjęcie przedstawiające Ulotkę',
			},
			{
				src: ulotka4,
				alt: 'Czwarte Zdjęcie przedstawiające Ulotkę',
			},
		],
	},
	{
		title: 'Oferty',
		images: [
			{
				src: oferta1,
				alt: 'Pierwsze Zdjęcie przedstawiające Ofertę',
			},
			{
				src: oferta2,
				alt: 'Drugie Zdjęcie przedstawiające Ofertę',
			},
			{
				src: oferta3,
				alt: 'Trzecie Zdjęcie przedstawiające Ofertę',
			},
		],
	},
	{
		title: 'Vouchery',
		images: [
			{
				src: voucher1,
				alt: 'Pierwsze Zdjęcie przedstawiające Voucher',
			},
			{
				src: voucher2,
				alt: 'Drugie Zdjęcie przedstawiające Voucher',
			},
			{
				src: voucher3,
				alt: 'Trzecie Zdjęcie przedstawiające Voucher',
			},
		],
	},
	{
		title: 'Inne',
		images: [
			{
				src: inne1,
				alt: 'Pierwsze Zdjęcie przedstawiające Inne',
			},
			{
				src: inne2,
				alt: 'Drugie Zdjęcie przedstawiające Inne',
			},
		],
	},
];

export default data;
