import MainPhotos from '../components/Main/MainPhotos';
import '../components/Main/Main.scss';
import MainList from '../components/Main/MainList';

const MainPage = () => {
	return (
		<>
			<MainPhotos />
			<MainList />
		</>
	);
};

export default MainPage;
