import { useState } from 'react';

const LazyImage = ({ src, alt, className }) => {
	const [isLoaded, setIsLoaded] = useState(false);

	return (
		<>
			{!isLoaded && (
				<div
					className='imageHolder'
				/>
			)}

			<img
				src={src}
				alt={alt}
				onLoad={() => setIsLoaded(true)}
				style={{
					opacity: isLoaded ? 1 : 0,
					transition: 'opacity 0.3s ease-in',
				}}
			/>
		</>
	);
};

export default LazyImage;
